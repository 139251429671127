import axios from 'axios'
import store from '~/store/store'
import router from '~/router/router'
import Config from '~/config'
import EventBus from '~/eventbus'

axios.defaults.baseURL = Config.BaseURL

axios.interceptors.request.use(function (config) {
    if (store.getters.isLoggedIn) {
        if (config.headers.common) {
            config.headers.common.Authorization = store.getters.authToken
        } else {
            config.headers.common = { Authorization: store.getters.authToken }
        }
    } else if (router.currentRoute.name === 'displayland-view') {
        config.headers.common = { 'X-Displayland-Authorization': router.currentRoute.params.id }
    }

    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (res) {
    return res
}, function (res) {
    if (res.response) {
        if (res.response && res.response.status >= 500) {
            // Notification.error({title: 'Erreur ' + res.response.status, message: 'Une erreur serveur est survenue, veuillez réessayer ultérieurement'});
        } else if (res.response.status === 401) {
            if (store.getters.isLoggedIn) {
                // Token likely expired, clear authentication
                store.dispatch('logout')
                setTimeout(() => {
                    // yes, we check AGAIN, but we avoid getting spammed if multiple calls are set at the same time
                    if (store.getters.isLoggedIn) {
                        EventBus.$emit('toast-message', {
                            type: 'error',
                            message: 'Votre session a expiré, veuillez vous reconnecter'
                        })
                    }
                }, 50)
            }

            if (global.cashRegisterIPC && global.cashRegisterIPC.emit) {
                global.cashRegisterIPC.emit('auth-error', `session expired, received 401 on ${res.config.url}`)
            }

            router.push('/').catch(() => {})
        }
    }

    return Promise.reject(res)
})

export default {}
