import consts from "../consts"

const onlineModules = [
    { text: "Planning", value: "planning", requiredPerm: "admin:planning", hasLandingPage: true },
    { text: "Contrôle d'accès", value: "ctm", requiredPerm: "admin:ctm", hasLandingPage: true },
    { text: "Clients", value: "clients", requiredPerm: "admin:clients", hasLandingPage: true },
    { text: "Statistiques", value: "stats", requiredPerm: "admin:stats", hasLandingPage: true },
    { text: "Spa", value: "spa", requiredPerm: "admin:spa", hasLandingPage: true },
    { text: "Casiers", value: "lockers", requiredPerm: "admin:lockers", hasLandingPage: true },
    { text: "Backoffice", value: "front" },
    { text: "Gestion commerciale", requiredPerm: "admin:business", value: "business", hasLandingPage: true },
    { text: "Odéon", value: "odeon" },
    { text: "Activités périodiques", value: "activities", requiredPerm: "admin:activities", hasLandingPage: true },
    { text: "Devis", value: "quotations", requiredPerm: "admin:quotations", hasLandingPage: true },
    { text: "Bornes", value: "kiosks", requiredPerm: "admin:kiosks", hasLandingPage: true },
    { text: 'Affichage centralisé', value: 'displayland', requiredPerm: "admin:displayland", hasLandingPage: true }
]

const bookingTerminalPlanningTypes = [
    { text: "Créneaux groupés par activité", value: consts.BOOKING_TERMINAL_PLANNING_TYPES.GROUPED_BY_ACTIVITY },
    { text: "Créneaux mélangés", value: consts.BOOKING_TERMINAL_PLANNING_TYPES.MIXED_ACTIVITIES },
]

const billingModeTypes = [
    { text: "Au créneau", value: consts.BILLING_MODE_TYPES.SESSION, icon: "mdi-calendar" },
    { text: "Horaire", value: consts.BILLING_MODE_TYPES.TIME, icon: "mdi-timeline-clock" },
    { text: "Horaire au prorata", value: consts.BILLING_MODE_TYPES.PRORATED_TIME, icon: "mdi-clock-alert" },

]

const billImageOptions = [
    { text: 'Ne rien afficher', value: consts.BILL_IMAGE_OPTION.NONE },
    { text: "Utiliser le logo", value: consts.BILL_IMAGE_OPTION.LOGO },
    { text: "Utiliser le fond", value: consts.BILL_IMAGE_OPTION.BACKGROUND },
]

export { onlineModules, bookingTerminalPlanningTypes, billingModeTypes, billImageOptions }
